<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew()'>新建</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :scroll='{ x: 1200}' :columns='columns' :data='loadData'>
        <span slot='id' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              <ellipsis :length='10' tooltip>{{ text }}</ellipsis>
            </a>
          </template>
        </span>
        <span slot='stitle' slot-scope='text, record'>
          <template>
            <ellipsis :length='32' tooltip>{{ text }}</ellipsis>
          </template>
        </span>
        <span slot='popular' slot-scope='text, record'>
          <template>
            <a-tag v-if='text==1' color='green'>是</a-tag>
            <a-tag v-if='text==0' color='cyan'>否</a-tag>
          </template>
        </span>
        <span slot='cover' slot-scope='text, record'>
          <template>
            <img :src='text' width='180px'>
          </template>
        </span>
        <span slot='status' slot-scope='text, record'>
          <template>
            <a-badge v-if='text=="draft"' dot status='default' :text='"草稿"' />
            <a-badge v-if='text=="online"' dot status='processing' :text='"已上架"' />
            <a-badge v-if='text=="offline"' dot status='warning' :text='"已下架"' />
          </template>
        </span>
        <span slot='price' slot-scope='text, record'>
          <template>
            <a-tag color='cyan'>原价：：¥{{ record.originalPrice }}</a-tag>
            <a-tag color='#87d068'>现价：¥{{ record.price }}</a-tag>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
        <template>
          <a-space>
            <a @click='handleEdit(record)'>编辑</a>
            <a v-if='record.status=="draft"' @click='handleStatus(record,"online")'>上架</a>
            <a v-if='record.status=="online"' @click='handleStatus(record,"draft")'>下架</a>
          </a-space>
        </template>
      </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryProductions, upstateProduction, removeProduction } from '@/api/production'

const columns = [
  {
    width: 100,
    title: '商品ID',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 200,
    title: '商品名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  {
    width: 100,
    title: '商品类型',
    dataIndex: 'categoryName',
    scopedSlots: { customRender: 'categoryName' }
  },
  {
    width: 100,
    title: '最受欢迎',
    dataIndex: 'popular',
    scopedSlots: { customRender: 'popular' }
  },
  {
    width: 100,
    title: '产品状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 100,
    title: '产品售价',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    width: 150,
    title: '创建时间',
    dataIndex: 'createTime'
  },
  {
    width: 150,
    title: '上架时间',
    dataIndex: 'onlineTime'
  },
  {
    width: 150,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ActivityList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryProductions(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {

  },
  methods: {
    handleNew() {
      this.$router.push('/production/edit')
    },
    handleEdit(record) {
      this.$router.push({ path: '/production/edit', query: { id: record.id } })
    },
    handleStatus(record, status) {
      upstateProduction({ id: record.id, status: status }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleSticky(record, status) {
      stickyCampusArticle({ id: record.id, sticky: status }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      remove({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleView(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
